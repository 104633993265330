
jQuery(function($) {
	/**
	 * スムーススクロール
	 */

	$('.js-scroll').on('click',function() {
		var speed = 400; // ミリ秒
		var href= $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		var position = target.offset().top;
		$('body,html').animate({scrollTop:position-60}, speed, 'swing');
		return false;
	});

	/**
	 * パララックス
	 */
	$(window).paroller();

	/**
	 * パララックス
	 */
	$("#header-recruit").colorbox({
		maxWidth:"90%",
		maxHeight:"90%",
		opacity: 0.7
	});

	/**
	 * トップボタン
	 */
	$(window).on("scroll", function() {

		if ($(this).scrollTop() > 700) {
			$('.m-btn-top').fadeIn("fast");
		} else {
			$('.m-btn-top').fadeOut("fast");
		}

		var scrollHeight = $(document).height();
		var scrollPosition = $(window).height() + $(window).scrollTop();
		var footHeight = $("#footer").innerHeight();

		if ( scrollHeight - scrollPosition  <= footHeight ) {
			$(".m-btn-top").css({
				"position":"absolute",
				"bottom": footHeight+40
			});
		} else {
		// それ以外の場合は元のcssスタイルを指定
			$(".m-btn-top").css({
				"position":"fixed",
				"bottom": "40px"
			});
		}
	});

	$('.js-slider').sliderPro({
		width: 720,//高さ
		height: 405,//高さ
		buttons: false,
		fade: true,
		thumbnailPointer: true,//アクティブなサムネイルにマークを付ける
		thumbnailWidth: 117,//サムネイルの横幅
		thumbnailHeight: 80//サムネイルの縦幅
	 });


	 $(window).scroll(function () {
       let _top = $(this).scrollTop();
       if(_top > 750){
         $('.js-fadein').addClass('_display');
       }else{
         $('.js-fadein').removeClass('_display');
       };
     });

});
